import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {useCookies} from 'react-cookie';

function LocationRouteHoc({component: Component, ...rest}) {
  const [cookies] = useCookies();
  const ref = rest.location.pathname + rest.location.search;
  return (
    <Route
      {...rest}
      render={(prop) =>
        cookies?.userAddress?.location ? (
          <Component {...prop} />
        ) : (
          <Redirect to={`/search-location?ref=${ref}`} />
        )
      }
    />
  );
}

export default LocationRouteHoc;
