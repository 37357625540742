import * as actionTypes from '../actionTypes';

const initialState = {
  boltxCart: [],
  boltxSearchResult: {
    result: [],
    __metadata: {},
  },
  boltxOffers: {},
  completedBoltxDemo: false,
};

export default function boltXReducers(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_BOLTX_CART:
      return {
        ...state,
        boltxCart: action.payload,
      };
    case actionTypes.SET_BOLTX_SEARCH_RESULT:
      return {
        ...state,
        boltxSearchResult: action.payload,
      };
    case actionTypes.SET_BOLTX_OFFERS:
      return {
        ...state,
        boltxOffers: action.payload,
      };
    case actionTypes.SET_BOLTX_DEMO: {
      return {
        ...state,
        completedBoltxDemo: action.payload,
      };
    }
    case actionTypes.UPDATE_BOLTX_SEARCH_RESULT: {
      const temp = {...state.boltxSearchResult};
      if (temp.result) {
        action.payload.result &&
          action.payload.result.length > 0 &&
          action.payload.result.forEach((res) => {
            temp.result.push(res);
          });
      }
      temp['__metadata'] = action.payload.__metadata;
      return {
        ...state,
        boltxSearchResult: temp,
      };
    }
    case actionTypes.CLEAR_BOLTX_CART:
      return {
        boltxCart: [],
        boltxSearchResult: {
          result: [],
          __metadata: {},
        },
        boltxOffers: {},
      };
    default:
      return state;
  }
}
