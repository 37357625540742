import * as actionTypes from '../actionTypes';

const initialState = {
  countryCode: '+91',
  mobileNumber: '',
  otpSent: false,
  apiLoading: false,
  loginErr: false,
  userData: {},
  truecallerReqID: '',
  userAddresses: [],
};

export default function authReducers(state = initialState, action) {
  switch (action.type) {
    case actionTypes.COUNTRY_CODE:
      return {
        ...state,
        countryCode: action.payload,
      };
    case actionTypes.MOBILE_NUMBER:
      return {
        ...state,
        mobileNumber: action.payload,
      };
    case actionTypes.OTP_SENT:
      return {
        ...state,
        otpSent: action.payload,
      };
    case actionTypes.LOGIN_API_LOADING:
      return {
        ...state,
        apiLoading: action.payload,
      };
    case actionTypes.LOGIN_ERROR:
      return {
        ...state,
        loginErr: action.payload,
      };
    case actionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        mobileNumber: '',
        otpSent: false,
        userData: action.payload,
      };
    case actionTypes.SET_USER:
      return {
        ...state,
        userData: {
          ...state.userData,
          user: action.payload,
        },
      };
    case actionTypes.UPDATE_LOGIN_DATA:
      const update = {...state.userData};
      if (update.user) {
        update['user'] = action.payload;
      }
      return {
        ...state,
        userData: update,
      };
    case actionTypes.TRUECALLER_REQ_ID:
      return {
        ...state,
        truecallerReqID: action.payload,
      };
    case actionTypes.SET_USER_ADDRESS:
      return {
        ...state,
        userAddresses: action.payload,
      };
    case actionTypes.ADD_USER_ADDRESS:
      return {
        ...state,
        userAddresses: [action.payload, ...state.userAddresses],
      };
    case actionTypes.CLEAR_USER:
      localStorage.removeItem('auth_token');
      return {
        countryCode: '+91',
        mobileNumber: '',
        otpSent: false,
        apiLoading: false,
        loginErr: false,
        userData: {},
        truecallerReqID: '',
        userAddresses: [],
      };
    default:
      return state;
  }
}
