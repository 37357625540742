import * as actionTypes from '../actionTypes';

const initialState = {
  loading: '',
  localCart: [],
  locationErr: '',
  storeCart: '',
  brandCart: '',
  addonSelection: [],
  imageItemSection: {},
};

export default function cartReducers(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_CART_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case actionTypes.SET_LOCAL_CART:
      return {
        ...state,
        localCart: action.payload,
      };
    case actionTypes.SET_STORE_CART:
      return {
        ...state,
        storeCart: action.payload,
      };
    case actionTypes.SET_BRAND_CART:
      return {
        ...state,
        brandCart: action.payload,
      };
    case actionTypes.SET_LOCATION_ERR:
      return {
        ...state,
        locationErr: action.payload,
      };
    case actionTypes.SAVE_SELECTED_ADDON:
      return {
        ...state,
        addonSelection: action.payload,
      };
    case actionTypes.IMAGE_ITEM_SELECTION: {
      return {
        ...state,
        imageItemSection: action.payload,
      };
    }
    case actionTypes.CLEAR_CART:
      return {
        loading: '',
        localCart: [],
        locationErr: '',
        storeCart: '',
        addonSelection: [],
        imageItemSection: {},
      };
    default:
      return state;
  }
}
