export const API_URL = process.env.REACT_APP_API_URL;
// export const API_URL = 'http://localhost:8080/v1';
export const MAP_KEY = process.env.REACT_APP_MAP_KEY;
export const RZP_KEY = process.env.REACT_APP_RZP_KEY;
export const GA_KEY = process.env.REACT_APP_GA_KEY;
export const UI_PATH = process.env.REACT_APP_UI_PATH;

export const getSingleAddressStructure = (address) => {
  let result = {
    addressLine: '',
    city: '',
    state: '',
    country: '',
    zip: '',
    location: '',
  };
  //pincode:
  const findAddressZip = address?.address_components
    ? address.address_components?.find((a) => a?.types?.includes('postal_code'))
    : '';
  if (findAddressZip) {
    result['zip'] = findAddressZip?.long_name;
  }

  //country:
  const findAddressCountry = address?.address_components
    ? address.address_components?.find((a) => a?.types?.includes('country'))
    : '';
  if (findAddressCountry) {
    result['country'] = findAddressCountry?.long_name;
  }

  //state:
  const findAddressState = address?.address_components
    ? address.address_components?.find((a) =>
        a?.types?.includes('administrative_area_level_1')
      )
    : '';
  if (findAddressState) {
    result['state'] = findAddressState?.long_name;
  }

  //city:
  let findAddressCity = address?.address_components
    ? address.address_components.find((a) => a?.types?.includes('locality'))
    : '';
  if (!findAddressCity)
    findAddressCity = address?.address_components
      ? address.address_components.find((a) =>
          a?.types?.includes('administrative_area_level_2')
        )
      : '';
  if (findAddressCity) {
    result['city'] = findAddressCity?.long_name;
  }

  //addressLine:
  result['addressLine'] = address.formatted_address;

  return result;
};

export const getAddressStructure = (data) => {
  let result = {
    addressLine: '',
    city: '',
    state: '',
    country: '',
    zip: '',
    location: '',
  };

  //pincode:
  const findZip = data.find((a) => a?.types?.includes('postal_code'));
  const findAddressZip = findZip?.address_components
    ? findZip.address_components?.find((a) => a?.types?.includes('postal_code'))
    : '';
  if (findAddressZip) {
    result['zip'] = findAddressZip?.long_name;
  }

  //country:
  const findCountry = data.find((a) => a?.types?.includes('country'));
  const findAddressCountry = findCountry?.address_components
    ? findCountry.address_components?.find((a) => a?.types?.includes('country'))
    : '';
  if (findAddressCountry) {
    result['country'] = findAddressCountry?.long_name;
  }

  //state:
  const findState = data.find((a) =>
    a?.types?.includes('administrative_area_level_1')
  );
  const findAddressState = findState?.address_components
    ? findState.address_components?.find((a) =>
        a?.types?.includes('administrative_area_level_1')
      )
    : '';
  if (findAddressState) {
    result['state'] = findAddressState?.long_name;
  }

  //city:
  let findCity = data.find((a) => a?.types?.includes('locality'));
  if (!findCity)
    findCity = data.find((a) =>
      a?.types?.includes('administrative_area_level_2')
    );
  let findAddressCity = findCity?.address_components
    ? findCity.address_components.find((a) => a?.types?.includes('locality'))
    : '';
  if (!findAddressCity)
    findAddressCity = findCity?.address_components
      ? findCity.address_components.find((a) =>
          a?.types?.includes('administrative_area_level_2')
        )
      : '';
  if (findAddressCity) {
    result['city'] = findAddressCity?.long_name;
  }

  //addressLine:
  let findAddress = data.find((a) => a?.types?.includes('premise'));
  if (!findAddress)
    findAddress = data.find((a) => a?.types?.includes('establishment'));
  if (!findAddress)
    findAddress = data.find((a) => a?.types?.includes('street_address'));
  result['location'] = findAddress?.formatted_address;
  result['addressLine'] = findAddress?.formatted_address?.split(
    findAddressCity?.long_name
  )[0];

  return result;
};
