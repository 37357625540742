import * as actionTypes from '../actionTypes';

const initialState = {
  message: [],
  confirmation: false,
  bargainData: [],
  finalPrice: null,
  finishBargain: false,
  userAttempt: 0,
};

export default function bargainReducers(state = initialState, action) {
  switch (action.type) {
    case actionTypes.ADD_MESSAGE: {
      const temp = [...state.message];
      temp.push(action.payload);
      return {
        ...state,
        message: temp,
      };
    }
    case actionTypes.USER_CONFIRMATION:
      return {
        ...state,
        confirmation: action.payload,
      };
    case actionTypes.BARGAIN_DATA:
      return {
        ...state,
        bargainData: action.payload,
      };
    case actionTypes.SET_FINAL_PRICE:
      return {
        ...state,
        finalPrice: action.payload,
      };
    case actionTypes.SET_FINISH_BARGAIN:
      return {
        ...state,
        finishBargain: action.payload,
      };
    case actionTypes.SET_USER_ATTEMPT:
      return {
        ...state,
        userAttempt: action.payload,
      };
    case actionTypes.CLEAR_BARGAIN:
      return {
        ...state,
        message: [],
        confirmation: false,
        finalPrice: null,
        finishBargain: false,
        userAttempt: 0,
      };
    default:
      return state;
  }
}
