import * as actionTypes from '../actionTypes';

const initialState = {
  veg: false,
  loading: false,
};

export default function miscReducers(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_VEG:
      return {
        ...state,
        veg: action.payload,
      };
    case actionTypes.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case actionTypes.RESET_MISC:
      return {
        veg: false,
        loading: false,
      };
    default:
      return state;
  }
}
