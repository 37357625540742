import * as actionTypes from '../actionTypes';

const initialState = {
  restData: {},
  restLoading: false,
  slideIndex: 0,
  itemList: [],
  itemLoading: false,
  itemCombos: [],
  categoryList: [],
  categoryLoading: false,
  searchList: [],
  ratingsPageVisibility: false,
  likedItems: [],
  topSoldItems: [],
  topCategoryItems: [],
  repeatItem: [],
  searchItem: '',
  globalSearch: {
    result: [],
    __metadata: {},
  },
  activeSearch: 'stores',
  nameSearch: false,
  nameSearchData: {
    result: [],
    __metadata: {},
  },
};

export default function storeReducers(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_REST_DATA:
      return {
        ...state,
        restData: action.payload,
      };
    case actionTypes.SET_REST_LOADING:
      return {
        ...state,
        restLoading: action.payload,
      };
    case actionTypes.SET_SLIDE_INDEX:
      return {
        ...state,
        slideIndex: action.payload,
      };
    case actionTypes.SET_ITEM_DATA:
      return {
        ...state,
        slideIndex: 0,
        itemList: action.payload,
      };
    case actionTypes.SET_ITEMS_COMBOS_DATA:
      return {
        ...state,
        itemCombos: action.payload,
      };
    case actionTypes.SET_ITEM_LOADING:
      return {
        ...state,
        itemLoading: action.payload,
      };
    case actionTypes.SET_CATEGORY_DATA:
      return {
        ...state,
        categoryList: action.payload,
      };
    case actionTypes.SET_CATEGORY_LOADING:
      return {
        ...state,
        categoryLoading: action.payload,
      };
    case actionTypes.SET_SEARCH_DATA:
      return {
        ...state,
        searchList: action.payload,
      };
    case actionTypes.RATINGS_PAGE_VISIBILITY:
      return {
        ...state,
        ratingsPageVisibility: action.payload,
      };
    case actionTypes.INIT_TOP_LIKED_ITEM:
      return {
        ...state,
        likedItems: action.payload,
      };
    case actionTypes.INIT_TOP_SOLD_ITEM:
      return {
        ...state,
        topSoldItems: action.payload,
      };
    case actionTypes.INIT_TOP_SOLD_CATEGORY_ITEM:
      return {
        ...state,
        topCategoryItems: action.payload,
      };
    case actionTypes.INIT_REPEAT_ORDER:
      return {
        ...state,
        repeatItem: action.payload,
      };
    case actionTypes.SET_SEARCH_ITEM:
      return {
        ...state,
        searchItem: action.payload,
      };
    case actionTypes.SET_GLOABL_SEARCH:
      return {
        ...state,
        globalSearch: action.payload,
      };
    case actionTypes.SET_ACTIVE_SEARCH:
      return {
        ...state,
        activeSearch: action.payload,
      };
    case actionTypes.SET_NAME_SEARCH:
      return {
        ...state,
        nameSearch: action.payload,
      };
    case actionTypes.SET_NAME_SEARCH_DATA:
      return {
        ...state,
        nameSearchData: action.payload,
      };
    case actionTypes.SET_NAME_SEARCH_DATA_PAGINATION: {
      const temp = {...state.nameSearchData};
      if (temp.result) {
        action.payload.result &&
          action.payload.result.length > 0 &&
          action.payload.result.forEach((res) => {
            temp.result.push(res);
          });
      }
      temp['__metadata'] = action.payload.__metadata;
      return {
        ...state,
        nameSearchData: temp,
      };
    }
    case actionTypes.RESET_SOURCE:
      return {
        restData: {},
        restLoading: false,
        itemList: [],
        itemCombos: [],
        itemLoading: false,
        categoryList: [],
        categoryLoading: false,
        searchList: [],
        ratingsPageVisibility: false,
        likedItems: [],
        topSoldItems: [],
        topCategoryItems: [],
        repeatItem: [],
        searchItem: '',
        globalSearch: {
          result: [],
          __metadata: {},
        },
        activeSearch: 'stores',
        nameSearch: false,
        nameSearchData: {
          result: [],
          __metadata: {},
        },
      };
    default:
      return state;
  }
}
