import React, {lazy, useEffect} from 'react';
import './App.scss';
import {ThemeProvider} from '@material-ui/core/styles';
import {lightTheme} from './utils/themes/theme';
import ReactGA from 'react-ga';
import {GA_KEY} from './utils/constants';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Route, Switch, useHistory} from 'react-router-dom';
import suspenseHoc from './hoc/suspenseHoc';
import LandingRouteHoc from './hoc/LandingRouteHoc';
import LocationRouteHoc from './hoc/LocationRouteHoc';
import PrivateRouteHoc from './hoc/PrivateRouteHoc';
import LoadingModal from './modules/LoadingModal';

const LandingPage = lazy(() => import('./pages/LandingPage'));
const SearchLocation = lazy(() => import('./pages/SearchLocation'));
const HomePage = lazy(() => import('./pages/HomePage'));
const StorePage = lazy(() => import('./pages/StorePage'));
const CartPage = lazy(() => import('./pages/CartPage'));
const CouponPage = lazy(() => import('./pages/CouponPage'));
const BargainBotPage = lazy(() => import('./pages/BargainBotPage'));
const BoltXPage = lazy(() => import('./pages/BoltXPage'));
const BoltXOfferPage = lazy(() => import('./pages/BoltXOfferPage'));
const SearchPage = lazy(() => import('./pages/SearchPage'));
const LoginPage = lazy(() => import('./pages/LoginPage'));
const ProfilePage = lazy(() => import('./pages/ProfilePage'));
const AmigosPage = lazy(() => import('./pages/AmigosPage'));
const OrderTrackingPage = lazy(() => import('./pages/OrderTrackingPage'));
const ShortVideoPage = lazy(() => import('./pages/ShortVideoPage'));
const LeadGenPage = lazy(() => import('./pages/LeadGenPage'));
const NeateatsPage = lazy(() => import('./pages/NeateatsPage'));

function App() {
  const history = useHistory();
  ReactGA.initialize(GA_KEY);
  useEffect(() => {
    /** GA Pageview*/
    ReactGA.pageview(history.location.pathname + history.location.search);
  }, [history.location.pathname, history.location.search]);

  useEffect(() => {
    window.onload = function () {
      const appVersion = window.localStorage.getItem('appVersion');
      if (!appVersion) {
        window.localStorage.setItem('appVersion', '0.05.11b');
      } else if (appVersion !== '0.05.11b') {
        toast.info('updating new version');
        setTimeout(() => {
          window.localStorage.clear();
          window.localStorage.setItem('appVersion', '0.05.11b');
          window.location.reload();
        }, 2000);
      }
    };
  }, []);

  return (
    <ThemeProvider theme={lightTheme}>
      <Switch>
        <LandingRouteHoc exact path="/" component={suspenseHoc(LandingPage)} />
        <Route
          exact
          path="/search-location"
          component={suspenseHoc(SearchLocation)}
        />
        <LocationRouteHoc
          exact
          path="/home"
          component={suspenseHoc(HomePage)}
        />
        <Route
          exact
          path="/store/:store_id/:brand_id"
          component={suspenseHoc(StorePage)}
        />
        <LocationRouteHoc
          exact
          path="/neateats/:name"
          component={suspenseHoc(NeateatsPage)}
        />
        <Route path="/short-videos" component={suspenseHoc(ShortVideoPage)} />
        <LocationRouteHoc path="/cart" component={suspenseHoc(CartPage)} />
        <LocationRouteHoc path="/coupon" component={suspenseHoc(CouponPage)} />
        <LocationRouteHoc
          exact
          path="/bargain"
          component={suspenseHoc(BargainBotPage)}
        />
        <LocationRouteHoc
          exact
          path="/bolt-x"
          component={suspenseHoc(BoltXPage)}
        />
        <LocationRouteHoc
          exact
          path="/bolt-x/offers"
          component={suspenseHoc(BoltXOfferPage)}
        />
        <LocationRouteHoc
          exact
          path="/search"
          component={suspenseHoc(SearchPage)}
        />
        <Route exact path="/login" component={suspenseHoc(LoginPage)} />
        <PrivateRouteHoc
          exact
          path="/profile"
          component={suspenseHoc(ProfilePage)}
        />
        <PrivateRouteHoc
          exact
          path="/profile/amigos"
          component={suspenseHoc(AmigosPage)}
        />
        <PrivateRouteHoc
          path="/track"
          component={suspenseHoc(OrderTrackingPage)}
        />
        <Route exact path="/lead-gen" component={suspenseHoc(LeadGenPage)} />
        <Route component={suspenseHoc(LandingPage)} />
      </Switch>
      <LoadingModal />
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange={false}
        pauseOnHover={false}
      />
    </ThemeProvider>
  );
}

export default App;
