import * as actionTypes from '../actionTypes';

const initialState = {
  storeList: [],
  storeLoading: false,
  filter: {
    ratings: '',
    cuisine: '',
  },
  searchValue: '',
  banners: [],
};

export default function homeReducers(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SAVE_STORES:
      return {
        ...state,
        storeList: action.payload,
      };
    case actionTypes.STORE_LOADING:
      return {
        ...state,
        storeLoading: action.payload,
      };
    case actionTypes.SET_FILTER:
      return {
        ...state,
        searchValue: '',
        filter: action.payload,
      };
    case actionTypes.SET_SEARCH_VALUE:
      return {
        ...state,
        filter: {
          ratings: '',
          cuisine: '',
        },
        searchValue: action.payload,
      };
    case actionTypes.SET_BANNERS_LIST:
      return {
        ...state,
        banners: action.payload,
      };
    case actionTypes.RESET_STORES:
      return {
        storeList: {
          result: [],
          __metadata: {},
        },
        storeLoading: false,
        filter: {
          ratings: '',
          cuisine: '',
        },
        searchValue: '',
        banners: [],
      };
    default:
      return state;
  }
}
