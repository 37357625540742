import React from 'react';
import Lottie from 'react-lottie';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import {useSelector} from 'react-redux';
import * as animationDataLoading from '../../assets/animations/loading';
import * as animationDataLoadingCoupon from '../../assets/animations/couponLoading';

function LoadingModal() {
  const itemLoading = useSelector((state) => state.store.itemLoading);
  const categoryLoading = useSelector((state) => state.store.categoryLoading);
  const loadingCoupon = useSelector((state) => state.order.loadingCoupon);
  const defaultLoading = {
    loop: true,
    autoplay: true,
    animationData: animationDataLoading.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const defaultLoadingCoupon = {
    loop: true,
    autoplay: true,
    animationData: animationDataLoadingCoupon.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return (
    <>
      <Dialog
        style={{padding: '0 !important', zIndex: 1500}}
        maxWidth={'xs'}
        open={itemLoading || categoryLoading}
      >
        <DialogContent
          style={{padding: 16, paddingTop: 0, textAlign: 'center'}}
        >
          <Lottie
            options={defaultLoading}
            height={150}
            width={150}
            isClickToPauseDisabled={true}
          />
          <div
            style={{
              fontSize: 12,
              fontWeight: 500,
              marginTop: -20,
            }}
          >
            TOASTING &apos;EM BUNS
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        style={{padding: '0 !important', zIndex: 1500}}
        maxWidth={'xs'}
        open={loadingCoupon}
      >
        <DialogContent style={{padding: 16}}>
          <Lottie
            options={defaultLoadingCoupon}
            height={150}
            width={150}
            isClickToPauseDisabled={true}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}

export default LoadingModal;
