import * as actionTypes from '../actionTypes';

const initialState = {
  details: {},
  categories: [],
  items: [],
  searchList: [],
};

export default function catalogReducers(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_CATALOG_DETAILS:
      return {
        ...state,
        details: action.payload,
      };
    case actionTypes.SET_CATALOG_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };
    case actionTypes.SET_CATALOG_ITEMS:
      return {
        ...state,
        items: action.payload,
      };
    case actionTypes.SET_ITEM_SEARCH_DATA:
      return {
        ...state,
        searchList: action.payload,
      };
    case actionTypes.RESET_ORDER:
      return {
        details: {},
        categories: [],
        items: [],
        searchList: [],
      };
    default:
      return state;
  }
}
