/** source actiontypes for fetching items and restaurant data */
export const SET_SLIDE_INDEX = 'SET_SLIDE_INDEX';
export const SET_REST_DATA = 'SET_REST_DATA';
export const SET_REST_LOADING = 'SET_REST_LOADING';
export const SET_ITEM_DATA = 'SET_ITEM_DATA';
export const SET_ITEM_LOADING = 'SET_ITEM_LOADING';
export const SET_ITEMS_COMBOS_DATA = 'SET_ITEMS_COMBOS_DATA';
export const SET_CATEGORY_DATA = 'SET_CATEGORY_DATA';
export const SET_CATEGORY_LOADING = 'SET_CATEGORY_LOADING';
export const SET_SEARCH_DATA = 'SET_SEARCH_DATA';
export const RATINGS_PAGE_VISIBILITY = 'RATINGS_PAGE_VISIBILITY';
export const INIT_TOP_LIKED_ITEM = 'INIT_TOP_LIKED_ITEM';
export const INIT_TOP_SOLD_ITEM = 'INIT_TOP_SOLD_ITEM';
export const INIT_TOP_SOLD_CATEGORY_ITEM = 'INIT_TOP_SOLD_CATEGORY_ITEM';
export const INIT_REPEAT_ORDER = 'INIT_REPEAT_ORDER';
export const RESET_SOURCE = 'RESET_SOURCE';
export const SET_SEARCH_ITEM = 'SET_SEARCH_ITEM';
export const SET_GLOABL_SEARCH = 'SET_GLOABL_SEARCH';
export const SET_ACTIVE_SEARCH = 'SET_ACTIVE_SEARCH';
export const SET_NAME_SEARCH = 'SET_NAME_SEARCH';
export const SET_NAME_SEARCH_DATA = 'SET_NAME_SEARCH_DATA';
export const SET_NAME_SEARCH_DATA_PAGINATION =
  'SET_NAME_SEARCH_DATA_PAGINATION';
