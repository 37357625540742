import React, {useEffect} from 'react';
import {Route, Redirect} from 'react-router-dom';
import {useCookies} from 'react-cookie';
import queryString from 'query-string';

function LandingRouteHoc({component: Component, ...rest}) {
  const [cookies, setCookies] = useCookies();

  useEffect(() => {
    const cookieOption = {
      path: '/',
    };
    if (rest.location.search !== '') {
      const values = queryString.parse(rest.location.search);
      if (values.affiliator_id) {
        setCookies('affiliator_id', values.affiliator_id, cookieOption);
      }
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Route
      {...rest}
      render={(prop) =>
        cookies.userAddress === undefined ||
        (cookies.userAddress && !cookies.userAddress.confirmed) ? (
          <Component {...prop} />
        ) : (
          <Redirect to={'/home'} />
        )
      }
    />
  );
}

export default LandingRouteHoc;
