import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import homeReducers from './homeReducers';
import storeReducers from './storeReducers';
import miscReducers from './miscReducers';
import cartReducers from './cartReducers';
import orderReducers from './orderReducers';
import authReducers from './authReducers';
import bargainReducers from './bargainReducers';
import boltXReducers from './boltXReducers';
import catalogReducers from './catalogReducers';

const reducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    home: homeReducers,
    store: storeReducers,
    misc: miscReducers,
    cart: cartReducers,
    order: orderReducers,
    auth: authReducers,
    bargain: bargainReducers,
    boltx: boltXReducers,
    catalog: catalogReducers,
  });

export default reducers;
